.App {
  text-align: center;
}





@font-face {
  font-family: "SFProBold";
  src: url("./assets/fonts/SF-Pro-Display/SF-Pro-Display-Black.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
}


@font-face {
  font-family: "SFProLight";
  src: url("./assets/fonts/SF-Pro-Display/SF-Pro-Display-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFProMedium";
  src: url("./assets/fonts/SF-Pro-Display/SF-Pro-Display-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFProRegular";
  src: url("./assets/fonts/SF-Pro-Display/SF-Pro-Display-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


.heroImage {
  margin-top: 30px;
}
.cursor {
  cursor: pointer;
}
.rightBoxImage{
  width: 230px;
  /* width:"100%";
  */
  height: 300px; 
}

#backers {
  padding-top: 100px;  /* Replace 100px with the height of your navbar */
  margin-top: -100px;  /* Replace 100px with the height of your navbar */
}
#about{
  padding-top: 100px;  /* Replace 100px with the height of your navbar */
  margin-top: 0px;  /* Replace 100px with the height of your navbar */
}
#community
{
  padding-top: 70px;  /* Replace 100px with the height of your navbar */
  margin-top: 70px;  /* Replace 100px with the height of your navbar */
}

.arrow{
  width: 35px;
  height: 35px;
  margin-right: 5px;
  margin-top: 5px;
}
.registerMainLogo1{
  height: 80px;
  width: 80px;
  /* width: 100px; */
  /* border: 2px solid yellow; */
 /* height: 100px;  */
}
.registerMainLogo2{
  width: 60px;
  height: 78px;
  /* width: 100px; */
  /* border: 2px solid yellow; */
 /* height: 100px;  */
}
.content-box {
  position: relative;
  /* Your other styles... */
}

.content-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); 
  transition: backdrop-filter 0.2s ease;
  pointer-events: none;
  backdrop-filter: none; /* No filter initially */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  pointer-events: none;
  opacity: 0 !important;
  transition: opacity 0s ease;


}

.content-box:hover .overlay {
  opacity: 1 !important;

} 

.content-box:hover::after {
  backdrop-filter: blur(13px); 
  /* Apply blur filter on hover */
  border-radius: 10px;
}

.registerSecondaryLogo1{
  width: 150px;
  /* border: 2px solid red; */
  /* height: 150px;  */
  margin-top: -10px;

}
.registerSecondaryLogo2{
  width: 150px;
  /* border: 2px solid red; */
  /* height: 150px;  */
  margin-top: -10px;

}


.backers{
  width:150px;
  margin: 5px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 1200px) {
  .Illustration{
    width:100%
  }
  .rightBoxImage{
    width:175px;
    height:220px
  }
}
@media only screen and (max-width: 860px) {
  .rightBoxImage{
    width:150px;
    height:220px
  }
}

@media only screen and (max-width: 735px) {
  .rightBoxImage{
    width:150px;
    /* height: 200px; */
  }
}
@media only screen and (max-width: 640px) {
  .rightBoxImage{
    width:120px;
    /* height: 200px; */
  }
}
@media only screen and (max-width: 600px) {
  .heroImage{
    margin-top: 30px;
  }
  .rightBoxImage{
    width:200px;
    height: 100%;
  }
  
  .backers{
    margin:22px;
    width:120px;
    /* height:100px */
  }
  .arrow{
    width: 20px;
    height: 20px;
  }
  .registerMainLogo1{
    width: 70px;
   height: 70px; 
  }
  .registerMainLogo2{
    width: 60px;
   height: 78px; 
  }
  .registerSecondaryLogo1{
    width: 100px;
    /* height: 100px;  */
  
  }
  .registerSecondaryLogo2{
    width: 100px;
    /* height: 100px;  */
  
  }
  .daffiLogo{
    margin-bottom: 25px;
  }
 
}
@media only screen and (max-width: 450px) {
  .rightBoxImage{
    width:180px;
    height:250px
  }
}
@media only screen and (max-width: 430px) {
  .rightBoxImage{
    width:150px;
    height:250px
  }
}

@media only screen and (max-width: 350px) { 
  .backers{
  
    width:100px;
    margin: 25px;
    /* height:100px */
  }
}